
.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.813rem 3.75rem;
    font-size: 1.125rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-blue {
    color: #fff;
    background-color: #009de0;
    border-color: #009de0;
}
.btn-blue:hover {
    color: #009de0;
    background-color: #fff;
    border-color: #009de0;
}

.btn.cta {
    color: #fff;
    background-color: #6c0;
    border-color: #6c0;
    min-width: 160px;
}
.btn.cta:hover {
    color: #6c0;
    background-color: #fff;
    border-color: #6c0;
    text-decoration: none;
}

.App {
    padding-left: 30px;
    padding-right: 30px;
    color: #516771;
}

.App h1 {
    font-size: 2.25rem;
    font-weight: 300;
    line-height: normal;
    margin: 0;
}
.App h4 {
    font-size: 1.4375rem;
    font-weight: 300;
    line-height: 1.2;
    margin: 0;
    margin-bottom: 0.8rem;
}
.App p {
    -webkit-hyphens: auto;
    hyphens: auto;
    letter-spacing: .05rem;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.2;
}

.App .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: 50px 45%;
}
.App .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #009de0;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #009de0 transparent transparent transparent;
}
.App .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.App .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.App .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.App > .container {
    position: relative;
}

.App > .container .headline {
    margin-top: 50px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.App > .container .headline .headline-icon {
    width: 80px;
    height: 80px;
    color: #fff;
    background-color: #009de0;
    border-radius: 50%;
    padding: 15px;
    margin-right: 15px;
}
.App > .container .headline .headline-icon svg {
    height: 100%;
    fill: #FFF;
}
.App > .container .headline h1 {
    font-size: 2.875rem;
    font-weight: normal;
    color: #516771;
}

/*
* PopUp
*/
.popUp {
    position: absolute;
    top: 25%;
    left: 50%;
    width: 80%;
    background-color: #FFF;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 15px;
    text-align: left;
    margin-left: -40%;
}
.popUp .btn {
    margin: 30px auto 0 auto;
    display: block;
}


.answer-container > div {
    margin-top: 15px;
    margin-bottom: 15px;
}
.answer-container .card {
    height: 100%;
    width: 100%;
    padding: 0;
    color: #516771;
}
.answer-container .card .card-body {
    max-width: 100%;
    text-align: left;
}
.question h2 {
    font-size: 1.25rem;
    font-weight: normal;
    color: #009de0;
    margin: 0;
}

.answer-container .card .card-img {
    background-color: #009de0;
    padding: 20px;
    text-align: center;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    min-height: 250px;
    max-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.answer-container .card .card-img img {
    max-height: 150px;
    width: auto;
    object-fit: contain;
}

.answer-container .card .card-text {
    /*
    max-height: 100px;
    overflow: hidden;
    */
}

@media (max-width:768px) {
    .answer-container {
        flex-direction: column;
    }
}

.nav-container {
    position: absolute;
    top: 50%;
    right: -25px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

}

.nav-btn {
    background-color: #FFF;
    width: 25px;
    height: 25px;
    overflow: hidden;
    border: 1px solid #009de0;
    border-radius: 13px;
    line-height: initial;
    margin-bottom: 5px;
}
.nav-btn span {
    display: none;
    color: #fff;
}

.nav-btn:hover {
    width: auto;
    min-width: 200px;
    background-color: #009de0;
    border-color: #FFF;
}
.nav-btn:hover span {
    display: inline-block;
}
.nav-btn.active {
    background-color: #009de0;
    border-color: #FFF;
}
.nav-btn.active:hover {
    width: 25px;
    min-width: 0;
}


.result > div {
    margin-top: 15px;
    margin-bottom: 15px;
}
.result .card {
    height: 100%;
    width: 100%;
    padding: 0;
    color: #516771;
}
.result .card .card-img {
    position: relative;
    height: 250px;
}
.result .card .card-img img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.result .card .card-img img.product {
    object-fit: contain;
}
.result .card .card-img .label {
    position: absolute;
    top: 10px;
    right: -4px;
    background-color: #fff;
    border: 1px solid #009de0;
    color: #009de0;
    padding: 1px 15px;
    font-size: .75rem;
    text-transform: uppercase;
}
.result .card .card-body {
    max-width: 100%;
    text-align: left;
}
.result .card .card-text {
    /*
    max-height: 100px;
    overflow: hidden;
    */
}

.product-detail .detail-img .inner {
    position: relative;
    /* max-height: 270px;
    height: 100%; */
}
.product-detail .detail-img .inner .label {
    position: absolute;
    top: 10px;
    right: -4px;
    z-index: 2;
    background-color: #fff;
    border: 1px solid #009de0;
    color: #009de0;
    text-transform: uppercase;
    font-size: 12px;
    font-family: Roboto,Arial,sans-serif;
    line-height: 1.2;
    padding: 2px 14px;
}
.product-detail .detail-img .inner:before {
    content: '';
    padding-top: 100%;
    display: inline-block;
} 
.product-detail .detail-img .inner img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
}
.product-detail h2 {
    margin-top: 0;
    margin-bottom: 5px;
    text-transform: uppercase;
    flex-basis: 100%;
    font-size: 2.25rem;
    font-weight: 300;
}
.product-detail .shortDescription {
    font-size: 1.875rem;
}
.product-detail .advantages {
    margin-top: 25px;
}
.product-detail .advantages .col {
    min-width: 25%;
}
.product-detail .advantages .col .inner{
    position: relative;
    padding-left: 25px;
}
.product-detail .advantages .col .inner:before {
    content: "";
    position: absolute;
    left: 0;
    top: 4px;
    display: inline-block;
    width: 14px;
    height: 14px;
    background: #516771;
    border-radius: 100%;
}
.product-detail .advantages .col .inner:after {
    content: "";
    position: absolute;
    top: 6px;
    left: 5px;
    display: block;
    width: 5px;
    height: 8px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: #fff;
    transform: rotate(45deg);
}
.product-detail .buttons {
    margin: 25px 0;
}
.product-detail .buttons a {
    margin-right: 25px;
    padding: 0.375rem 0.75rem;
}

.product-detail .related-products {
    background-color: #efeeee;
}
.product-detail .related-products h3 {
    color: #9EA6AC;
    font-weight: 300;
    padding-top: 15px;
    font-size: 1.875rem;
    padding-left: 22px;
}
.product-detail .related-products .prod-link {
    /* background-color: transparent;
    border: none; */
    width: 100%;
    color: #1c3c4a;
    text-decoration: none;
}
.product-detail .related-products .prod-img {
    background-color: #FFF;
    text-align: center;
    padding: 15px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.product-detail .related-products .prod-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.product-detail .related-products .prod-title {
    font-size: 1.125rem;
    line-height: 1.2;
    padding-top: 10px;
    color: #9EA6AC;
    letter-spacing: .05rem;
    font-weight: 300;
}

/* PRODUCT-SLIDER */
.slick-list,.slick-slider,.slick-track{position:relative;display:block}.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}.slick-slider{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{top:0;left:0}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}
.product-detail .slick-slider {

}
.product-detail .slick-slider .slick-list {
    
}
.product-detail .slick-slider .slick-list .slick-track {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 100%;
    margin-right: 0;
    margin-left: 0;
}
.product-detail .slick-slider .slick-list .slick-track .slick-slide {
    height: auto;
}
.product-detail .slick-slider .slick-list .slick-track .slick-slide > div {
    display: -ms-flexbox;
    display: flex;
    height: 100%;
}
.product-detail .slick-slider .slick-list .slick-track .slick-slide .card {
    border: none;
    border-radius: 0;
    padding: 10px;
    height: 100%;
    display: flex!important;
    background-color: transparent;
}

.product-detail .slick-slider .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-color: rgba(0,157,224,.9);
    border: 0;
    overflow: hidden;
    text-indent: -9999px;
    width: 20px;
    height: 40px;
    display: -ms-flexbox!important;
    display: flex!important;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    cursor: pointer;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
}
.product-detail .slick-slider .slick-arrow.slick-prev {
    left: 0;
}
.product-detail .slick-slider .slick-arrow.slick-prev:after {
    position: relative;
    bottom: 0;
    display: inline-block;
    width: 10px;
    height: 10px;
    content: "";
    border-width: 0.15em 0.15em 0 0;
    border-style: solid;
    border-color: #fff;
    left: 2px;
    transform: rotate(225deg);
}
.product-detail .slick-slider .slick-arrow.slick-next {
    right: 0;
}
.product-detail .slick-slider .slick-arrow.slick-next:after {
    position: relative;
    bottom: 0;
    display: inline-block;
    width: 10px;
    height: 10px;
    content: "";
    border-width: 0.15em 0.15em 0 0;
    border-style: solid;
    border-color: #fff;
    left: -2px;
    transform: rotate(45deg);
}
.product-detail .slick-slider .slick-arrow.slick-disabled {
    opacity: .5;
}